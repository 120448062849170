/* Add this to your CSS */
::-webkit-scrollbar {
  height: 8px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
/* Add this to your global CSS */
/* * {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

::-webkit-scrollbar {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
} */
