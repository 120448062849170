/* html {
  font-size: 14px;
}

body {
  font-family: var(--font-family);
  font-weight: normal;
  background: var(--surface-ground);
  color: var(--text-color);
  padding: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.tag-input span[data-id] {
  background-color: #f0f8ff;
  border-radius: 4px;
  /* padding: 2px 4px; */
  margin: 0 2px;
  font-weight: bold;
  cursor: pointer;
}

.tag-input span[data-id]:hover {
  background-color: #e0e8ff;
}
/* Styling for the Mention input */
.p-inputtext {
  color: var(--text-color); /* Light text color */
  font-family: 'Muli', sans-serif; /* Use the Muli font */
  font-size: 14px; /* Font size consistent with the app */
  font-weight: 400;
  background-color: var(--background-color);
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Add padding inside the input */
  margin-bottom: 10px;
  outline: none; /* Remove default outline */
  width: 100%; /* Ensure it spans the container */
  height: auto; /* Fixed height */
  box-sizing: border-box; /* Include padding in width calculation */
  /* overflow: hidden; Prevent scrollbars */
  resize: none; /* Prevent resizing */
  line-height: 1.5; /* Matches the font size for consistent spacing */
}

/* Focus state to add glow effect */
.p-inputtext:focus {
  border: 1px solid #00ffcc; /* Highlight border on focus */
  box-shadow: 0 0 5px #00ffcc; /* Glow effect */
}

/* Placeholder color for better visibility */
.p-inputtext::placeholder {
  color: var(--text-color); /* Dynamic placeholder color */
  opacity: 0.6;
}

/* Styling for the parent container */
.mention-wrapper {
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  max-width: 400px;
  /* height: auto; */
  margin: 0 auto;
}

/* Styling for the rendered mentions */
.mention-editor span[data-id] {
  background-color: #f0f8ff; /* Light blue background for mentions */
  color: #007bff; /* Blue text for mentions */
  border-radius: 4px;
  padding: 2px 4px;
  margin: 0 2px;
  font-weight: bold;
  cursor: pointer;
}

.mention-editor span[data-id]:hover {
  background-color: #e6f7ff; /* Lighter blue on hover */
}

textarea[p-mention-input] {
  color: blue;
  font-weight: 400;
}
[contenteditable]:empty:before {
  content: attr(placeholder);
  color: #aaa;
  pointer-events: none;
}
/* RichTextField.css */

.full-width-toolbar .MuiTiptap-RichTextField-content {
  width: 100% !important;
}

.full-width-toolbar .MuiTiptap__Editor {
  width: 100%;
}

.full-width-toolbar .MuiTiptap__MenuControlsContainer {
  width: 100% !important;
  justify-content: space-between;
}

.full-width-toolbar .MuiTiptap__MenuControlsContainer > * {
  flex-grow: 1;
  text-align: left;
}
.mention-dropdown {
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.mention-option {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mention-option:hover {
  background-color: #f0f0f0;
}
