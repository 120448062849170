/* RichTextField.css */

.full-width-toolbar .MuiTiptap-RichTextField-content {
  width: 100% !important;
}

.full-width-toolbar .MuiTiptap__Editor {
  width: 100%;
}

.full-width-toolbar .MuiTiptap__MenuControlsContainer {
  width: 100% !important;
  justify-content: space-between;
}

.full-width-toolbar .MuiTiptap__MenuControlsContainer > * {
  flex-grow: 1;
  text-align: left;
}
.tiptap p {
  margin: 0;
  line-height: 1.5;
}

/* You can add additional customizations for headers, lists, etc., as needed */
.tiptap h1,
.tiptap h2 {
  margin: 0;
  font-size: 1.2rem;
}
.css-1wnk55e-FieldContainer-root-FieldContainer-outlined-RichTextField-root-RichTextField-outlined
  .css-E2Alw3-RichTextField-content-ref {
  padding: 0.3rem !important; /* Use !important to override any conflicting styles */
}
.css-1ps06yo-FieldContainer-root-FieldContainer-outlined-FieldContainer-focused-RichTextField-root-RichTextField-outlined
  .css-E2Alw3-RichTextField-content-ref {
  padding: 0.3rem !important; /* Use !important to override any conflicting styles */
}
.css-1v7ntnc-FieldContainer-root-FieldContainer-outlined-FieldContainer-focused-RichTextField-root-RichTextField-outlined
  .css-E2Alw3-RichTextField-content-ref {
  padding: 0.3rem !important; /* Use !important to override any conflicting styles */
}
.css-lwaa97-FieldContainer-root-FieldContainer-outlined-RichTextField-root-RichTextField-outlined
  .css-E2Alw3-RichTextField-content-ref {
  padding: 0.3rem !important;
}
