/* ✅ Ensure Google Picker appears centered & above everything */
div.picker-dialog {
  z-index: 1300 !important; /* Higher than MUI Drawer (default 1200) */
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 90vw !important;
  max-width: 1000px !important;
  height: 80vh !important;
  max-height: 700px !important; /* Prevent excessive height */
  border-radius: 10px !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2) !important;
  overflow: hidden !important; /* ✅ Prevent extra space */
}

/* ✅ Remove extra padding/margin from content */
div.picker-dialog-content {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important; /* Ensures it takes full space */
  justify-content: center !important;
  align-items: center !important;
}

/* ✅ Make sure the iframe doesn't add extra space */
iframe.picker-frame {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* ✅ Fix button container having unnecessary space */
div.picker-dialog-buttons {
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px !important;
  margin: 0 !important;
  width: 100% !important;
  box-sizing: border-box !important;
}
