@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animated-letter {
  opacity: 0; /* Start fully transparent */
  animation-name: fadeIn;
  animation-fill-mode: forwards; /* Ensure the letter stays visible after animation */
  /* Adjust the animation-duration as needed */
}
