.sprint-grid-row--optimization {
  border-left: 3px solid;
  border-color: rgba(5, 189, 159, 0.4);
}

.sprint-grid-row--feature {
  border-left: 3px solid;
  border-color: rgba(240, 123, 39, 0.4);
}

.sprint-grid-row--refactor {
  border-left: 3px solid;
  border-color: rgba(234, 127, 138, 0.4);
}
.sprint--completed {
  border-left: 3px solid;
  border-color: rgba(41, 235, 86);
  opacity: 50%;
}
